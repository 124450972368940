<template>
  <div id="adList-actionDialog">
    <el-dialog
      title="图文视频推广操作"
      :visible.sync="show"
      :close-on-press-escape="false"
      destroy-on-close
      @open="open"
      @close="reset"
    >
      <el-form ref="form" :model="form" :rules="rules" label-width="90px">
        <el-form-item label="主标题" prop="mainTitle">
          <el-input
            v-model="form.mainTitle"
            placeholder="请输入主标题"
          ></el-input>
        </el-form-item>
        <el-form-item label="副标题">
          <el-input
            v-model="form.subtitle"
            placeholder="请输入副标题"
          ></el-input>
        </el-form-item>
        <el-form-item label="封面" prop="coverId">
          <imgUpload ref="imgUpload" @success="imgUploadSuccess" />
        </el-form-item>
        <el-form-item label="广告位置">
          <el-radio-group v-model="form.position">
            <el-radio label="首页"></el-radio>
            <el-radio label="车主生活"></el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="视频"
          prop="videoId"
          v-if="form.position === '首页'"
        >
          <fileUpload
            ref="fileUpload"
            url="/ad/video/upload"
            :previewUrl="videoPreviewUrl"
            @success="fileUploadSuccess"
          />
        </el-form-item>
        <el-form-item label="富文本">
          <span
            class="richText-btn"
            @click="richTextPreview"
            v-if="form.detailUrl"
            >预览</span
          >
          <span class="richText-btn" @click="richTextEdit">编辑</span>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="medium" @click="show = false">取 消</el-button>
        <el-button
          type="primary"
          size="medium"
          @click="confirmBtn"
          :loading="loading"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <richTextDialog
      ref="richTextDialog"
      :url="form.detailUrl"
      @save="richTextSave"
    />
    <richTextPreview ref="richTextPreview" />
  </div>
</template>
<script>
export default {
  components: {
    imgUpload: () => import("@/components/imgUpload.vue"),
    fileUpload: () => import("@/components/fileUpload.vue"),
    richTextDialog: () => import("../components/richTextDialog.vue"),
    richTextPreview: () => import("../components/richTextPreview.vue"),
  },
  data() {
    return {
      show: false,
      loading: false,
      promotionId: "",
      imgPreviewUrl: "",
      videoPreviewUrl: "",
      form: {
        mainTitle: "",
        coverId: "",
        position: "首页",
        videoId: "",
        subtitle: "",
        detailUrl: "",
      },
      rules: {
        mainTitle: [
          { required: true, message: "请输入主标题", trigger: "blur" },
        ],
        advtisementPosition: [
          { required: true, message: "请选择广告位置", trigger: "change" },
        ],
        coverId: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (!this.form.coverId) {
                callback(new Error("封面图不能为空"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    richTextPreview() {
      this.$refs.richTextPreview.iframe = this.form.detailUrl;
      this.$refs.richTextPreview.show = true;
    },
    richTextEdit() {
      this.$refs.richTextDialog.show = true;
    },
    richTextSave(value) {
      this.form.detailUrl = value;
    },
    open() {
      if (this.imgPreviewUrl) {
        this.$nextTick(() => {
          this.$refs.imgUpload.previewUrl = this.imgPreviewUrl;
        });
      }
    },
    reset() {
      this.promotionId = "";
      this.imgPreviewUrl = null;
      this.videoPreviewUrl = null;
      this.$set(this, "form", {
        mainTitle: "",
        coverId: "",
        position: "首页",
        videoId: "",
        subtitle: "",
        detailUrl: "",
      });
      this.$refs["form"].resetFields();
    },
    imgUploadSuccess(fileId) {
      this.form.coverId = fileId;
    },
    fileUploadSuccess(res) {
      this.imgUploadSuccess(res.cover.fileId);
      this.$refs.imgUpload.previewUrl = res.cover.fileUrl;
      this.form.videoId = res.video.fileId;
      this.videoPreviewUrl = res.video.fileUrl;
    },
    confirmBtn() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          try {
            this.loading = true;

            let url = this.promotionId
              ? "/ad/promotion/edit"
              : "/ad/promotion/add";
            let params = {
              ...this.form,
            };

            if (this.promotionId) {
              params.promotionId = this.promotionId;
            }
            if (this.form.position) {
              params.videoId = null;
            }

            let res = await this.$http.post(url, params);
            if (res.code === 0) {
              this.$emit("confirm");
              this.$message.success("操作成功");
              this.show = false;
            } else {
              this.$message.error(res.msg);
            }
            this.loading = false;
          } catch (err) {
            this.$message.error(err);
          } finally {
            this.loading = false;
          }
        }
      });
    },
  },
};
</script>
<style lang="scss">
#adList-actionDialog {
  & > .el-dialog__wrapper {
    .el-dialog {
      width: 400px;
      .el-dialog__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
        border: 1px solid rgba(20, 34, 57, 0.13);
        .el-dialog__title {
          font-size: 14px;
          color: #142239;
          position: relative;
          z-index: 2;
          font-weight: bold;
          &::after {
            content: "";
            display: inline-block;
            width: 100%;
            height: 3px;
            background-color: $main-color;
            position: absolute;
            left: 0;
            bottom: 4px;
            z-index: -1;
          }
        }
        .el-dialog__headerbtn {
          position: initial;
          i {
            color: black;
          }
        }
      }
      .el-dialog__body {
        padding: 15px;
        .el-select {
          width: 100%;
        }
        .richText-btn {
          margin-right: 10px;
          color: rgb(169, 165, 165);
          cursor: pointer;
          &:hover {
            color: $main-color;
          }
        }
      }
      .el-dialog__footer {
        text-align: center;
        .dialog-footer {
          .el-button--default {
            border-color: $main-color;
            color: $main-color;
          }
        }
      }
    }
  }
}
</style>
